<template>
  <div class="right-chart-1">
    <div class="rc1-header">{{ config.name }}</div>
    <div class="lc2-details">
      {{
        type === 'count'
          ? '总数(件)'
          : '价值 ' + '( ' + config.amountUnit + ' )'
      }}<span class="value">{{
        type === 'count' ? config.total : config.amount
      }}</span>
      <div class="toggle-wrap">
        <span
          @click="changeType('count')"
          :class="{ 'span-active': activeIndex === '0', desc: true }"
          >数量</span
        >
        /
        <span
          @click="changeType('amount')"
          :class="{ 'span-active': activeIndex === '1', desc: true }"
          >价值</span
        >
      </div>
    </div>
    <div class="rc1-chart-container">
      <!-- <dv-capsule-chart class="right" v-model:config="config" /> -->
      <capsule-chart-2
        class="right"
        v-model:config="config"
        :type="type"
        ref="chart2"
      ></capsule-chart-2>
    </div>

    <dv-decoration-2 style="height: 10px" />
  </div>
</template>

<script>
import * as assetsApi from '@/api/ams/assets'
import capsuleChart2 from './capsule-chart-2.vue'
export default {
  name: 'RightChart1',
  data() {
    return {
      config: {},
      change: true,
      type: 'count',
      activeIndex: '0',
      data: null
    }
  },
  components: {
    capsuleChart2
  },
  methods: {
    changeType(type) {
      if (type === 'count') {
        this.activeIndex = '0'
        this.type = type
      }
      if (type === 'amount') {
        this.activeIndex = '1'
        this.type = type
      }
      this.setConfig(this.data)
      this.$refs.chart2.initChartData()
    },
    handelMoney(amount) {
      const AmountUnitlist = ['元', '万元', '亿元', '兆元']
      let strAmount = amount.toString()
      let amountUnit = ''
      AmountUnitlist.find((item, index) => {
        let newAmount = ''
        const strIndex = strAmount.indexOf('.')
        if (strIndex !== -1) {
          newAmount = strAmount.substring(0, strIndex)
        } else {
          newAmount = strAmount
        }
        if (newAmount.length < 5) {
          amountUnit = item
          return true
        } else {
          strAmount = ((newAmount * 1) / 10000).toString()
        }
      })
      const money = { num: 0, unit: '' }
      money.num = (strAmount * 1).toFixed(2)
      money.unit = amountUnit
      return money
    },
    setConfig(data) {
      this.data = data
      assetsApi
        .categoryAssetsReport(data.id)
        .then((res) => {
          if (res.code === 0) {
            let count = 0
            let amount = 0
            res.data.reverse()
            res.data.forEach((item) => {
              count += item.count
              amount += Number(item.total)
              if (this.type === 'count') {
                item.value = item.count
              }
              if (this.type === 'amount') {
                item.value = item.total
              }
            })
            const amountObj = this.handelMoney(amount)

            this.config = {
              name: data.name,
              total: count,
              amount: amountObj.num,
              amountUnit: amountObj.unit,
              unit: '件',
              type: this.type,
              data: res.data,
              colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b']
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less">
.right-chart-1 {
  width: 100%;
  height: 60%;
  padding: 10px 0 0 10px;
  display: flex;
  flex-direction: column;

  .rc1-header {
    font-size: 24px;
    font-weight: bold;
    height: 60px;
  }

  .rc1-chart-container {
    flex: 1;
    display: flex;
  }

  .left {
    width: 30%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      font-size: 34px;
      color: #096dd9;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .right {
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .lc2-details {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 10px;
    .value {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      flex: 1;
    }
    .toggle-wrap {
      margin-right: 10px;
      cursor: pointer;
      .span-active {
        color: #7bbcee;
      }
    }
  }
}
</style>
